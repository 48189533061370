.mainContainer {
  min-height: 65vh !important;
}

.title {
  font-size: calc(.6vw + 1.5rem);
  font-weight: bold;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 6rem;
}

.link {
  display: block;
  font-size: calc(.6vw + 1.2rem);
  font-weight: bold;
  background-color: var(--cor02);
  color: white;
  padding: 1rem 1.5rem;
  text-align: center;
  border: 4px solid var(--cor02);
  box-shadow: 2px 2px 4px rgb(0 0 0 / 18%);
  transition: .4s;
  min-width: 500px;
}

.link:hover,
.link:focus {
  color: var(--cor02);
  background-color: white;
  border-color: var(--cor04);
}

@media (max-width: 560px) {

  .link {
    max-width: 100%;
    min-width: 200px;
  }

}