.container {
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  justify-content: center;
  max-width: 96rem;
  padding: 0 3rem;
  margin: 3rem auto;
}

.container h2 {
  background-color: var(--cor01);
  text-align: center;
  border-bottom: 2px solid var(--cor04);
  padding-bottom: .5rem;
  margin-bottom: 1.5rem;
}

.listItem {
  margin: 0 1.5rem 1rem;
}

.listItem p {
  font-size: calc(.5vw + 1rem);
}

.listItem strong {
  display: inline-block;
  color: var(--cor04);
  margin-right: .5rem;
}

.listItem strong span {
  display: inline-block;
  width: 15px;
  height: 2px;
  background-color: var(--cor04);
  margin-right: .5rem;
  margin-bottom: .4rem;
}

.row {
  display: block;
  background-color: var(--cor04);
  width: 100%;
}

@media(max-width: 650px) {

  .container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .row {
    display: none;
  }

}