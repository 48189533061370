.loadingContainer {
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: #FFEAF4;
  color: white;
  padding: 9rem 1.5rem;
}

.loadingContent {
  display: block;
  margin: 12rem auto;
  width: fit-content;
}

.loadingContent img {
  margin-bottom: 3rem;
}

.loadingContent span {
  display: block;
  background-color: white;
  width: 100%;
  height: 20px;
  border-radius: .4rem;
  overflow: hidden;
  position: relative;
}

.loadingContent span::before {
  content: 'loading';
  font-size: 1.6rem;
  font-weight: bold;
  color: #FFEAF4;
  text-transform: uppercase;
  text-align: center;
  display: block;
  background-color: #C52F58;
  min-width: 200px;
  width: 80%;
  height: 20px;
  border-radius: .4rem;
  animation: loadingAnimate 1.8s infinite alternate ease-in-out;
  opacity: .9;
}

@keyframes loadingAnimate {
  from {
    transform: translateX(-250px);
  }
  to {
    transform: translateX(300px);
  }
}

.circularLoading {
  display: block;
  background-color: transparent;
  border: 10px solid var(--cor04);
  border-left-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  animation: loadingCircular .8s infinite ease-in-out;
  margin: 3rem auto;
}

@keyframes loadingCircular {
  from {
    transform: rotate(0deg);
  } 
  to {
    transform: rotate(360deg);
  }
}

@media (max-width:650px) {

  .loadingContainer {
    padding: 3rem 1.5rem;
  }

  .loadingContent {
    margin: 3rem auto;
  }

  .circularLoading {
    margin: 0 auto;
  }

}