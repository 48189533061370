.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  background-color: var(--cor01);
  border-top: 5px solid var(--cor02);
  padding: 1rem 1rem 1.5rem;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.container div:first-child {
  max-width: 800px;
}

.container div:first-child h2 {
  font-size: calc(.6vw + 1rem);
  margin-bottom: .5rem;
}

.container div:first-child p {
  font-size: calc(.4vw + 1rem);
  text-indent: 1.5rem;
  max-width: 600px;
}

.container div:last-child {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.container div:last-child button {
  border: 2px solid var(--cor02);
  box-shadow: 2px 2px 4px rgb(0 0 0 / 18%);
  color: var(--cor06);
  display: block;
  font-size: calc(.2vw + 1.2rem);
  font-weight: 700;
  padding: .5rem 1rem;
  transition: .2s;
  width: fit-content;
  cursor: pointer;
}

.container div:last-child button:hover {
  border-color: var(--cor04);
  transition: .2s;
}

.container div:last-child button:last-child {
  background-color: var(--cor02);
  color: var(--cor01);
  border: 2px solid var(--cor05);
}

.container div:last-child button:last-child:hover {
  border-color: var(--cor04);
  transition: .2s;
}

.container a {
  font-weight: bold;
  color: var(--cor03);
  margin: 0 .5rem;
  border-bottom: 2px solid transparent;
  transition: .2s;
}

.container a:hover {
  border-color: var(--cor03);
}