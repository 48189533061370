.container {
  display: flex;
  justify-content: center;
  align-items: center; 
  gap: 1.5rem;
}

.container li {
  position: relative;
  transition: transform .2s;
  box-shadow: 2px 2px 2px rgba(0,0,0,.2);
}

.container li:hover,
.container li:focus {
  transform: scale(1.2);
  box-shadow: 2px 2px 2px rgba(0,0,0,.4);
}

.container a::after {
  content: attr(data-tip);
  font-size: calc(.4vw + 1rem);
  background-color: var(--cor05);
  color: var(--cor01);
  padding: .5rem;
  text-align: center;
  position: absolute;
  top: 0;
  visibility: hidden;
  transition: visibility .1s;
}

.container a:hover::after {
  visibility: visible;
}

.container img {
  width: 12rem;
  min-width: 40px;
}