.container {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 99%
}

.container::-webkit-scrollbar {
  background-color: var(--cor02);
  height: 6px;
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--cor05);
  border-radius: 4px;
}

.table {
  font-weight: 400;
  text-align: center;
  border-collapse: collapse;
  font-size: var(--font-basis);
  text-transform: uppercase;
}

.table th,
.table td {
  border-right: 2px solid var(--cor04);
  padding: 0 0.5rem;
}

.table th {
  border-bottom: 2px solid var(--cor04)
}