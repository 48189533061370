
.player {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.player > div {
  width: 100%;
}

.player > button {
  display: block;
  background-color: var(--cor04);
  border: none;
  border-radius: 50%;
  min-width: 80px;
  width: 40%;
  height: 80px;
  cursor: pointer;
  position: relative;
  border: 2px solid transparent;
  transition: .2s ease-out;
}

.player > button:hover,
.player > button:focus {
  border-color: var(--cor02);
}

button.playSmallButton,
button.pauseSmallButton {
  min-width: 40px;
  width: 40px;
  height: 40px;
}

.playSmallButton::after {
  content: '';
  display: block;
  border: 10px solid transparent;
  border-left: 10px solid var(--cor01);
  position: absolute;
  top: 25%;
  left: 40%;
  transition: .4s;
}

.playButton::after {
  content: '';
  display: block;
  border: 20px solid transparent;
  border-left: 20px solid var(--cor01);
  position: absolute;
  top: 25%;
  left: 40%;
  transition: .4s;
}

.pauseSmallButton::before {
  content: '';
  display: block;
  background-color: var(--cor01);
  width: 6px;
  height: 20px;
  position: absolute;
  top: 25%;
  left: 25%;
  transition: .4s;
}

.pauseSmallButton::after {
  content: '';
  display: block;
  background-color: var(--cor01);
  width: 6px;
  height: 20px;
  position: absolute;
  top: 25%;
  right: 25%;
  transition: .4s;
}

.pauseButton::before {
  content: '';
  display: block;
  background-color: var(--cor01);
  width: 12px;
  height: 40px;
  position: absolute;
  top: 25%;
  left: 25%;
  transition: .4s;
}

.pauseButton::after {
  content: '';
  display: block;
  background-color: var(--cor01);
  width: 12px;
  height: 40px;
  position: absolute;
  top: 25%;
  right: 25%;
  transition: .4s;
}

.player div > input {
  display: block;
  background-color: var(--cor02);
  width: 100%;
  height: 5px;
  position: relative;
  margin-bottom: 5px;
  appearance: none;
  cursor: pointer;
}

.player div > input::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--cor05);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.player div > input::before {
  content: attr(data-initime);
  display: block;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 8px;
}

.player div > input::after {
  content: attr(data-fintime);
  display: block;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 8px;
  right: 0;
}

.volumeContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--cor02);
  padding: .5rem;
  border-radius: .5rem;
  position: relative;
  bottom: 1.5rem;
}

.volumeIcon {
  width: 35px;
  cursor: pointer;
}

.volumeContainer input.volume {
  background-color: var(--cor01);
  margin-bottom: 0;
}

.velocityContainer {
  position: relative;
  top: 1rem;
}

.velocityContainer > button {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  background: none;
  border: none;
  margin-left: auto;
  cursor: pointer;
  transition: .2s;
}

.velocityContainer > button:hover,
.velocityContainer > button:focus {
  color: var(--cor04);
}

.velocity {
  position: absolute;
  top: 1rem;
  right: 20px;
  background-color: var(--cor01);
  border: 1px solid var(--cor04);
  border-radius: .2rem;
  cursor: pointer;
}

.velocity > option {
  font-size: 1.8rem;
}

@media(max-width: 650px) {

  .player {
    flex-direction: column;
  }

  .player > button {
    width: 80px;
    order: 1;
  }

  .volumeContainer {
    bottom: 0;
    margin-bottom: 1.5rem;
  }
  
}