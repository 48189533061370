.container {
  margin-bottom: 3rem;
}

.container h2 {
  text-align: center;
  margin-top: 1rem;
}

.paragraphContainer {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  max-width: 96rem;
}

.paragraphContainer h3 {
  margin-top: .5rem;
  text-align: center;
}

.paragraphContainer img {
  margin: 0 auto;
}

@media (max-width: 650px) {

  .container p {
    margin: 0 auto;
  }

  .paragraphContainer {
    grid-template-columns: 1fr;
    padding: 0 3rem;
  }

}