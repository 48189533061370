.paragraph {
  font-size: calc(.6vw + 1rem);
  line-height: 2.5rem;
  text-indent: 1.5rem;
  height: fit-content;
}

@media (max-width: 650px) {
  
  .paragraph {
    line-height: 2rem;
  }

}