.container {
  position: fixed;
  top: 25%;
  left: 0;
  z-index: 5;
}

.border {
  background-color: var(--cor01);
  border-top: 4px solid var(--cor04);
  border-bottom: 4px solid var(--cor04);
  border-right: 4px solid var(--cor04);
  transition: .2s;
  animation: animaLeft .6s forwards ease-in;
  overflow-y: auto;
  max-height: 400px;
}

@keyframes animaLeft {

  from {
    transform: translateX(-120px);
  } to {
    transform: translateX(0);
  }

}

.border::-webkit-scrollbar {
  background-color: var(--cor01);
  width: 6px;
}

.border::-webkit-scrollbar-thumb {
  background-color: var(--cor05);
  border-radius: 4px;
}

.button {
  display: block;
  width: 25px;
  height: 25px;
  margin-left: .5rem;
  margin-bottom: .5rem;
  background-color: var(--cor01);
  border: 2px solid var(--cor04);
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.185);
  transition: .4s;
  position: relative;
}

.button:hover::after {
  content: attr(data-tip);
  font-size: var(--font-basis);
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  position: absolute;
  top: 10%;
  left: 120%;
}

.button:hover,
.button:focus {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.349);
}

.button > span {
  display: block;
  width: 15px;
  height: 2px;
  background-color: var(--cor04);
  margin: 0 auto;  
  box-shadow: 0 5px 0 0 var(--cor04);
  position: relative;
  transition: .4s;
}

.button > span::before {
  content: '';
  display: block;
  width: 15px;
  height: 2px;
  margin: 0 auto;
  background-color: var(--cor04);
  position: absolute;
  top: -5px;
}

.button.closeButton > span {
  transform: rotate(45deg);
  box-shadow: none;
}

.button.closeButton > span::before {
  transform: rotate(90deg);
  top: 0;
}

.title {
  font-size: calc(.4vw + 1.4rem);
  border-bottom: 2px solid var(--cor04);
  padding: .5rem 1.5rem;
}

.linkTitle {
  font-size: var(--font-basis);
  color: var(--cor01);
  background-color: var(--cor02);
  text-transform: uppercase;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

.arrow::after {
  content: '';
  display: inline-block;
  border: 6px solid transparent;
  border-top: 6px solid var(--cor03);
}

.arrowDown.arrow::after {
  position: relative;
  top: 5px;
  left: 4px;
}

.arrowUp.arrow::after {
  position: relative;
  bottom: 5px;
  left: 4px;
  transform: rotate(180deg);
}

.link,
.sublink {
  font-size: var(--font-basis);
  letter-spacing: .6px;
  width: fit-content;
  border-bottom: 2px solid transparent;
  transition: .2s;
  margin: .5rem 1.5rem .5rem;
}

.sublink {
  font-size: calc(.2vw + 1rem);
  margin-top: .5rem;
}

.link:hover,
.link:focus,
.sublink:hover,
.sublink:focus {
  border-bottom-color: var(--cor02);
}

.animation {
  animation: moveDown .6s forwards ease-out;
}

@keyframes moveDown {
  from {
    padding-top: 1.5rem;
  } 
  to {
    padding-top: initial;
  }
}

@media (max-width: 650px) {

  .container {
    top: 25%;
  }

  .border {
    max-height: 200px;
  }

}