.musics {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 3rem;
}

.music {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  background-color: var(--cor01);
  padding: 1.5rem;
  position: relative;
}

.album div > img {
  max-width: 200px;
  border: 2px solid var(--cor04);
  border-radius: 2px;
  overflow: hidden;
}

.music h3 {
  font-size: calc(.6vw + 1rem);
  text-transform: capitalize;
  max-width: 300px;
  max-height: 80px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: .4s;
}

.rollingTitle {
  animation: animaLeft 1.5s infinite alternate ease-in-out;
  padding-left: 1rem;
}

@keyframes animaLeft {
  from {
    transform: translateX(-60px);
  }
  to {
    transform: translateX(60px);
  }
}

.music > div:last-child {
  overflow: hidden;
}

.music h4 {
  font-size: calc(.4vw + 1rem);
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 1.5rem;
  transition: .4s;
  border-bottom: 2px solid transparent;
  width: fit-content;
}

.music h4:hover {
  border-bottom-color: var(--cor04);
}

.music h4 a {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.music audio {
  display: none;
}

.album {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.authorLinkHover::after {
  content: "";
}

.authorLinkHover:hover::after {
  content: 'See More from ' attr(data-author);
  display: block;
  background-color: var(--cor02);
  color: var(--cor01);
  font-size: calc(.4vw + .8rem);
  max-width: 93%;
  padding: .5rem;
  position: absolute;
  left: 2px;
  bottom: 2px;
  transition: .4s;
} 

.album img.authorLogo {
  position: absolute;
  bottom: 5px;
  left: 4px;
  border: none;
  max-width: 40px;
}

@media(max-width: 650px) {

  .music {
    display: block;
  } 

  .music div > img {
    margin: 0 auto;
  }

  .music h3,
  .music h4 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .music h4 {
    margin-bottom: 1rem;
  }

}