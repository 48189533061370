.button {
  display: block;
  background: none;
  border: none;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.button:hover {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.button:hover::after {
  content: 'Listen while browsing';
  display: block;
  font-size: 1.4rem;
  width: 150px;
  position: absolute;
  top: 0;
  left: 1.5rem;
}

.button > img {
  width: 15px;
}