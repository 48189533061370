
.descriptions {
  border-left: 2px solid var(--cor04);
  height: fit-content;
  margin-top: 1.5rem;
}

.descriptions > nav {
  border-bottom: 2px solid var(--cor04);
}

.descriptions > div {
  padding: 1rem 1.5rem;
}

.descriptionTypes {
  display: flex;
  align-items: center;
}

.descriptionTypes li {
  font-size: var(--font-basis);
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 .5rem;
  cursor: pointer;
  border-right: 2px solid var(--cor04);
  transition: .2s;
}

.descriptionTypes li:hover,
.descriptionTypes li:focus {
  color: var(--cor07);
}

.selected {
  background-color: var(--cor01);
}

div.animation > * {
  transition: padding .2s;
  animation: animaLeft .4s forwards ease-out;
}

@keyframes animaLeft {
  from {
    opacity: .6;
    padding-left: 6rem;
  }
  to {
    opacity: initial;
    padding: initial;
  }
}