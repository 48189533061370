* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  list-style: none;

  color: var(--cor06);
  font-family: 'Open Sans','Arial';
}

:root {
  --cor01:#FFEAF4;
  --cor02:#FF6699;
  --cor03:#C52F58;
  --cor04:#AE1942;
  --cor05:#D4426A;
  --cor06:#434142;
  --cor07:#6D6769;   

  --font-basis: calc(.5vw + 1rem);
  
  font-size: 62.5%;
} 

html, body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  background-color: var(--cor01);
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--cor05);
  border-radius: 4px;
}

img {
  display: block;
  max-width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container > main {
  min-height: 90vh;
}

.marginMain {
  margin-top: 16rem;
}

.imageTitle img {
  margin: 0 auto 3rem;
  width: fit-content;
  min-width: 30rem;
  padding: 0 1.5rem;
}

.row {
  display: block;
  background-color: #F1EBEE;
  width: 100%;
}

.animaOpacityDown {
  animation: animaOpacityDown 1s forwards ease-out;
}

.animaDown {
  animation: animaDown .5s forwards ease-out;
}

@keyframes animaOpacityDown {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }  
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animaDown {
  0% {
    padding-top: 6rem;
  }  
  100% {
    padding-top: initial;
  }
}

@media (max-width: 650px) {

  .marginMain {
    margin-top: 3rem;
  }

}
