.musicContainer {
  display: flex;
  gap: 1.5rem;
  background-color: var(--cor01);
  position: fixed;
  top: 80%;
  left: .5rem;
  animation: showAnima .6s forwards ease-in;
}

@keyframes showAnima {
  from {
    opacity: 0;
    transition: .4s;
  } to {
    opacity: 1;
  }
}

.rollingTitle {
  animation: animaLeft 1.5s infinite alternate ease-in-out;
  padding-left: 1rem;
}

@keyframes animaLeft {
  from {
    transform: translateX(-60px);
  }
  to {
    transform: translateX(60px);
  }
}

.hiddenPlayer {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -100%;
}

.album {
  position: relative;
  border: 2px solid var(--cor04);
  border-radius: 2px;
  overflow: hidden;
  height: fit-content;
}

.album > div img {
  max-width: 100px;
}

.album > a img {
  position: absolute;
  bottom: 5px;
  left: 4px;
  border: none;
  max-width: 20px;
}

.closeButton {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--cor04);
  background: none;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: .5rem;
  cursor: pointer;
}

.content {
  padding: 1rem 1rem 1rem 0;
  transition: opacity .2s;
  overflow: hidden;

}

.content > h3 {
  font-size: 1.2rem;
  margin-bottom: .5rem;
  max-width: 300px;
}

.content > h4 {
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 1.5rem;
  transition: .4s;
  border-bottom: 2px solid transparent;
  width: fit-content;
}

.content > h4:hover {
  border-bottom-color: var(--cor04);
}

.content > h4 a {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.content > div {
  gap: 1rem;
}

.content > div div:first-child {
  padding: .2rem;
}

.content > div div:first-child input {
  height: 2px;
}

.content > div div:first-child img {
  width: 25px;
}

.content > div div:last-child {
  top: .5rem;
}

.content > div input {
  height: 2px;
}

.content > div input::before,
.content > div input::after {
  font-size: 1.2rem;
  top: 4px;
}
