.container {
  max-width: 96rem;
  margin: 0 auto 6rem;
}

.container > h2 {
  margin-top: 6rem;
  margin-bottom: 3rem;
  text-align: center;
}

.colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}

.color img {
  margin: 0 auto;
}

.color > h4 {
  font-size: var(--font-basis);
  text-align: center;
  padding: .5rem;
  margin-top: 1rem;
  border-top: 2px solid var(--cor05);
  border-bottom: 2px solid var(--cor05);
}