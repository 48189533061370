.container {
  max-width: 96rem;
  width: 100vw;
  padding: 0 3rem;
}

.container > h2 {
  font-size: var(--font-basis);
  text-transform: uppercase;
  border-left: 2px solid var(--cor04);
  border-bottom: 2px solid var(--cor04);
  padding-left: .5rem;
  margin: 3rem 0;
  cursor: pointer;
}

.arrow::after {
  content: '';
  display: inline-block;
  border: 6px solid transparent;
  border-top: 6px solid var(--cor03);
}

.arrowDown.arrow::after {
  position: relative;
  top: 5px;
  left: 5px;
}

.arrowUp.arrow::after {
  position: relative;
  bottom: 4px;
  left: 5px;
  transform: rotate(180deg);
}

@media (max-width: 650px) {

  .container {
    padding: 0 1.5rem;
  }

}