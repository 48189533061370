.container {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 6rem;
  max-width: 96rem;
  margin: 0 auto 6rem;
  padding: 0 3rem;
}

.profile h3 {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0 1.5rem;
}


.difficulty {
  margin-top: .5rem;
}

.difficulty, 
.difficulty > ul{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.difficulty h3 {
  text-transform: uppercase;
  margin: 0;
}

@media (max-width:650px) {

  .container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .profile img {
    margin: 0 auto; 
  }

}