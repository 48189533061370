.paragraphContainer {
  display: grid;
  grid-template-columns: 1fr 0.2rem 1fr;
  gap: 3rem;
  max-width: 96rem;
  padding: 0 3rem;
  margin: 0 auto;
}

.downloadButton {
  display: block;
  font-size: var(--font-basis);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--cor06);
  padding: 1.5rem;
  border: 2px solid var(--cor02);
  box-shadow: 2px 2px 4px rgb(0 0 0 / 18%);
  margin: 3rem auto 0;
  width: fit-content;
  position: relative;
  transition: .2s;
}

.downloadButton:hover,
.downloadButton:focus {
  color: var(--cor07);
  border-color: var(--cor04);
}

.downloadButton::before {
  display: inline-block;
  content: "";
  background-color: #F1EBEE;
  width: 100px;
  height: 2px;
  position: absolute;
  top: 48%;
  left: 120%;
}

.downloadButton::after {
  display: inline-block;
  content: "";
  background-color: #F1EBEE;
  width: 100px;
  height: 2px;
  position: absolute;
  top: 48%;
  right: 120%;
}

.seeMoreButton {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  width: fit-content;
  color: var(--cor04);
  margin: 0 auto;
  margin-top: .5rem;
  margin-bottom: 3rem;
  transition: .2s;
}

.seeMoreButton:hover,
.seeMoreButton:focus {
  text-decoration: underline;
}

@media (max-width:650px) {

  .paragraphContainer {
    grid-template-columns: 1fr;
    grid-template-rows: auto .2rem auto;
    gap: 1.5rem;
  }

}