.container {
  margin: 0 auto;
  width: fit-content;
}

.container > h2 {
  text-align: center;
}

.moveContainer {
  margin: 3rem 0;
}

.moveContainer > h3, 
.moveContainer > h4 {
  padding: 0 3rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.moveContainer > h3 {
  font-size: var(--font-basis);
  font-weight: 700;
  text-transform: uppercase;
}

.moveContainer h5 {
  text-align: center;
  text-transform: none;
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 1.2rem;
}

.moveBox {
  display: grid;
  grid-template-columns: minmax(100px,auto) 1fr;
  justify-items: center;
  gap: 3rem;
  background-color: var(--cor01);
  padding: 3rem 6rem;
}

.moveBox > div:first-child {
  align-self: center;
}

.moveBox img {
  padding: 1.5rem 0;
  margin: 0 auto 1rem auto;
  max-width: 300px;
}

.tableContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 3rem;
}

@media (max-width: 650px) {

  .moveBox {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 3rem;
    width: fit-content;
    margin: 0 auto;
  }

  .moveBox img {
    max-width: 100%;
    padding: 0;
  }

  .moveContainer > h3, 
  .moveContainer > h4 {
    padding: 0 1.5rem;
  }

}