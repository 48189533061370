.container {
  background-color: var(--cor01);
  border-top: 1rem solid var(--cor02);
  border-bottom: 1rem solid var(--cor02);
  padding: 3rem 0;
  width: 100vw;
}

.paragraphContainer {
  display: grid;
  grid-template-columns: 1fr 0.2rem 1fr;
  padding: 0 3rem;
  gap: 3rem;
  margin: 0 auto;
  max-width: 96rem;
}

.readmore strong {
  color: var(--cor04);
  cursor: pointer;
  margin-left: .5rem;
  border-bottom: 2px solid transparent;
  transition: .2s;
}

.readmore strong:hover {
  border-color: var(--cor04);
}

.imageContainer {
  position: relative;
}

.imageContainer > div {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.imageContainer > div:first-child {
  padding-right: 1rem;
  position: relative;
  top: 0;
  z-index: 2;
}

.imageContainer > div:last-child {
  position: absolute;
  left: 14vw;
  bottom: 18%;
}

@media (max-width: 750px) {

  .paragraphContainer {
    grid-template-columns: 1fr;
    grid-template-rows: auto .2rem 1fr;
  } 

  .imageContainer > div {
    max-width: 420px;
  }

  .imageContainer > div:first-child {
    position: relative;
    left: -60px;
  }
  
  .imageContainer > div:last-child {
    position: relative;
    right: 40px;
  }

}

@media (max-width: 550px) {

  .imageContainer > div {
    max-width: 300px;
  }

}

@media (max-width: 450px) {

  .imageContainer > div {
    max-width: 220px;
  }

}

@media (max-width: 350px) {

  .imageContainer > div {
    max-width: 180px;
  }

  .imageContainer > div:first-child {
    position: relative;
    left: -40px;
  }

}