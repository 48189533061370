.container {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 6rem;
}

.messageContainer h2:first-child {
  font-size: calc(.6vw + 1.5rem);
}

.messageContainer h2,
.messageContainer h3 {
  font-size: calc(.6vw + 1rem);
  text-align: center;
}

.messageContainer h3 {
  margin-top: 1rem;
}

.messageContainer a {
  color: var(--cor04);
  margin-left: .5rem;
  border-bottom: 2px solid transparent;
  transition: .2s;
}

.messageContainer a:hover,
.messageContainer a:focus {
  border-bottom-color: var(--cor04);
}

@media(max-width:650px) {

  .container {
    flex-direction: column;
  }

  .container img {
    max-width: 300px;
  }

} 