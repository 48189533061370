.tagHeader {
  width: 100%;
}

.header {
  background-color: var(--cor01);
  width: 100%;
  border-bottom: 10px solid var(--cor02);
  position: fixed;
  z-index: 5;
  height: 11rem;
  transition: .4s;
}

.autoHeight {
  height: auto;
}

.navHeader {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: .5rem 1.5rem 1.5rem .5rem;
}

.divStick {
  width: 100%;
  top: 11rem;
  position: fixed;
  z-index: 4;
}

.navCharacters {
  background-color: var(--cor01);
  padding: 6rem 3rem 3rem 3rem;
  border-bottom: 10px solid var(--cor02);
  overflow-x: auto;
  margin-bottom: .5rem;
  transition: transform .6s;
  animation: navCharactersAnima .4s forwards ease-out;

}

@keyframes navCharactersAnima {
  from {
    transform: translateY(-60px);
  } to {
    transform: translateY(0);
  }
}

.navCharacters::-webkit-scrollbar {
  background-color: var(--cor01);
  height: 6px;
}

.navCharacters::-webkit-scrollbar-thumb {
  background-color: var(--cor05);
  border-radius: 4px;
}

.logo {
  margin: 0 2rem;
  min-width: 180px;
}

.listHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.listHeader li a,
.listHeader li button {
  font-size:calc(.4vw + 1rem);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  background: transparent;
  border: none;
  cursor: pointer;
}

.listHeader li a:hover,
.listHeader li button:hover {
  color: var(--cor07);
}

.arrow::after {
  content: '';
  display: inline-block;
  border: 6px solid transparent;
  border-top: 6px solid var(--cor03);
}

.arrowDown.arrow::after {
  position: relative;
  top: 5px;
  left: 4px;
}

.arrowUp.arrow::after {
  position: relative;
  bottom: 5px;
  left: 4px;
  transform: rotate(180deg);
}

@media (max-width: 650px) {

  .header {
    position: static;
    height: auto;
  }

  .divStick {
    position: static;
  }

  .navCharacters {
    padding: 1.5rem;
  }

  .navCharacters ul {
    justify-content: flex-start;
  }

  .navHeader {
    flex-wrap: wrap;
    gap: 1.5rem 3rem;
  }

  .logo {
    order: -1;
    width: 100%;
  }

  .logo img {
    margin: 0 auto;
  }

}

