.selection {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--cor04);
  margin-bottom: .5rem;
}

.selection button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 100px;
  padding: 0 1.5rem;
  transition: .4s;
}

.selection button:first-child {
  border-right: 2px solid var(--cor04);
}

.selection button:hover {
  background-color: var(--cor05);
  color: var(--cor01);
  transition: .4s;
}

button.active {
  background-color: var(--cor02);
  color: var(--cor01);
}