.container {
  background: border-box url(../assets/bg.png) border-box top no-repeat;
  background-color: var(--cor01);
  min-height: 60rem;
}

.navCharacters {
  max-width: 86rem;
  margin: 0 auto;
}

.navCharacters ul {
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0 1.5rem;
}

@media (max-width: 650px) {

  .container {
    background: border-box url(../assets/bg-mobile.png) border-box top no-repeat;
    min-height: 40rem;
  }

  .navCharacters ul {
    gap: .5rem;
  }

  .navCharacters ul li img {
    width: 6rem;
  }

}

@media (max-width: 350px) {

  .container {
    min-height: 30rem;

  }

  .navCharacters ul li img {
    width: 3rem;
  }

}