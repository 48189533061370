.moreMargin {
  margin-top: 18rem;
}

.container {
  padding: 0 6rem;
  margin-bottom: 6rem;
}

.container > h2 {
  text-align: center;
}

.boxWrapper {
  display: grid;
  grid-template-columns: minmax(100px,auto) 1fr;
  justify-items: center;
  gap: 3rem;
  background-color: var(--cor01);
  padding: 3rem;
}

.boxWrapper img {
  padding: 1.5rem 0;
  margin: 0 auto;
  max-width: 300px;
}

.boxWrapper p {
  margin-top: 1rem;
}

@media (max-width: 650px) {

  .moreMargin {
    margin-top: 3rem;
  }

  .boxWrapper {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .boxWrapper img {
    max-width: 100%;
    padding: 0;
  }

}