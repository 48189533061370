.container {
  display: block;
  width: 100%;
  background-color: var(--cor01);
  padding: 1rem 1.5rem 3rem ;
  text-align: center;
  text-transform: uppercase;
  border-top: 1rem solid var(--cor02);
}

.title {
  font-family: 'Bungee';
  font-size: 2.4rem;
  color: white;
  text-shadow:
  -2px -2px 0 var(--cor02),  
   2px -2px 0 var(--cor02),
   -2px 2px 0 var(--cor02),
    2px 2px 0 var(--cor02);
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.list li {
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;
  align-items: center;
  padding: .5rem;
}

.list li a:hover {
  color: var(--cor07);
}

.list li::before {
  display: inline-block;
  content: "";
  width: 10px;
  height: 2px;
  background-color: var(--cor03);
  margin-right: .5rem;
}

@media(max-width: 350px) {

  .title {
    font-size: 1.8rem;
    text-shadow:
    -2px -2px 0 var(--cor02),  
     2px -2px 0 var(--cor02),
     -2px 2px 0 var(--cor02),
      2px 2px 0 var(--cor02);
  }

  .list {
    gap: 1.5rem;
  }

}