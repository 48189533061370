.characteristics li {
  font-size: calc(.5vw + .8rem);
  color: var(--cor07);
  font-weight: 700;
}

.overviewStatus li::before,
.characteristics li::before {
  display: inline-block;
  content: "";
  width: 15px;
  height: 2px;
  background-color: var(--cor03);
  margin-bottom: 4px;
}

.characteristics li span {
  font-size: var(--font-basis);
  color: var(--cor07);
  text-transform: uppercase;
  margin: 0 .5rem;
}

.overview > h4 {
  font-size: var(--font-basis);
  font-weight: 700;
  color: var(--cor05);
  text-transform: uppercase;
  margin-bottom: 1.5rem;
} 

.overview > h4 span {
  text-transform: none;
  margin-left: .5rem;
}

.overview > p {
  margin-top: 1.5rem;
}

.overviewStatus {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 80px;
  padding-left: 1.5rem;
}

.overviewStatus li {
  font-size: var(--font-basis);
  font-weight: 700;
  color: var(--cor07);
  text-transform: capitalize;
}

.overviewStatus li span {
  color: var(--cor06);
  margin: 0 .5rem;
}